import React, { useEffect, useState } from 'react' //lazy

import { popupAPI } from 'apis'

import * as S from './style'

interface Props {
  data?: any
}

//props: Props
const PopupConfirm = ({ data = [] }: Props) => {
  // const userData: any = localStorage.getItem('User')
  const arrIds: any = localStorage.getItem('Popup')
  const [items, setItems] = useState<any>([])
  const [arrIdsClose, setArrIdsClose] = useState<any>([])

  const getPopup = async () => {
    const res: any = await popupAPI()
    // console.log(res);
    if (res) {
      setItems(res?.data)
    }
  }
  // console.log(check);

  useEffect(() => {
    getPopup()
  }, [])

  useEffect(() => {
    if (arrIds) {
      setArrIdsClose(arrIds)
    }
  }, [arrIds])

  const close = (id: string) => {
    // localStorage.setItem('Popup', 'true')
    setArrIdsClose((prev: any) => [...prev, id])
  }

  const closeBox = (id: string) => {
    let newArrIds: any
    if (arrIds) {
      newArrIds = [...arrIds, id]
    } else {
      newArrIds = [id]
    }
    setArrIdsClose((prev: any) => [...prev, id])
    localStorage.setItem('Popup', newArrIds)
  }

  return (
    <S.Wrapper>
      {items?.length > 0 &&
        items
          .sort(
            (prev: any, next: any) =>
              Number(prev?.sortno) - Number(next?.sortno)
          )
          .map((item: any) => (
            <S.Item
              key={item?.id}
              className={`${
                arrIdsClose.includes(item?.id) ? 'false' : ''
              } main`}
              style={{
                width: Number(item?.board_field5),
                height: Number(item?.board_field9)
              }}
            >
              <div className='title'>
                <h3>{item?.board_subject}</h3>
              </div>
              <div
                className='img'
                dangerouslySetInnerHTML={{ __html: item?.board_content ?? '' }}
              />
              <div className='content'>
                <div className='box' onClick={() => closeBox(item?.id)}>
                  {' '}
                </div>
                <div className='text'> &ensp;&emsp; 確認しました</div>
              </div>
              <div className='button'>
                <span onClick={() => close(item?.id)}>CLOSE</span>
              </div>
            </S.Item>
          ))}
    </S.Wrapper>
  )
}

export default PopupConfirm
