/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react' //lazy
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import btnAddValue from 'assets/icons/header/card-add.svg'
import btnWithDraw from 'assets/icons/header/withdraw.svg'
import btnPoint from 'assets/icons/header/bitcoin-convert.svg'
import btnNoti from 'assets/icons/header/sms-notification.svg'
import btnMess from 'assets/icons/header/notification-bing.svg'
import btnPartner from 'assets/icons/header/profile-2user.svg'
import btnMenu from 'assets/images/button/Icon_Menu_2.png'
import { UrlInternal } from 'common/constants/endpoints'
import logo from 'assets/images/login/logo.png'
import { RiArrowGoBackFill } from 'react-icons/ri'
import { Popover } from 'antd'
// import logo from 'assets/images/login/World_Logo.png'
// import { Popover, Tooltip } from 'antd'
import './style.css'
import avatar from 'assets/images/avatar.png'
import * as S from './style'
import changePoint from 'assets/images/Icon.png'
interface Props {
  onClick?: any
  className?: any
}

const HeaderPage = (props: Props) => {
  const history = useHistory()
  const { onClick, className } = props
  const dataMe = useSelector((state: any) => state.user)
  const [onShowMenu, setOnShowMenu] = useState<boolean>(false)
  const handleShow = () => {
    setOnShowMenu(!onShowMenu)
  }
  const Logout = () => {
    localStorage.removeItem('User')
    localStorage.removeItem('Popup')
    history.push(UrlInternal.LOGIN)
  }
  const location = useLocation()
  const { pathname } = location

  const dataMenu = [
    {
      url: UrlInternal.DEPOSIT,
      img: btnAddValue,
      label: '入金'
    },
    {
      url: UrlInternal.PAYMENT,
      img: btnWithDraw,
      label: '出金'
    },
    {
      url: UrlInternal.MESSAGE,
      img: btnMess,
      label: 'メッセージ'
    },
    // {
    //   url: UrlInternal.PARTNER,
    //   img: btnPartner,
    //   label: '파트너'
    // },
    {
      url: UrlInternal.NOTIFICATION,
      img: btnNoti,
      label: 'お知らせ'
    },
    {
      url: UrlInternal.POINT,
      img: btnPoint,
      label: 'ポイント交換'
    }
  ]

  useEffect(() => {
    if (onShowMenu) {
      const body: any = document.querySelector('body')
      body.style.overflow = 'hidden'
    } else {
      const body: any = document.querySelector('body')
      body.style.overflow = 'auto'
    }
  }, [onShowMenu])
  const content = (
    <div style={{ margin: '0px 10px', minWidth: '250px' }}>
      <S.Row>
        {dataMe?.dataUser && (
          <S.Avatar onClick={() => history.push(UrlInternal.USER)}>
            <S.AvatarImg>
              <img src={avatar} alt='avatar' />
            </S.AvatarImg>
            <p style={{ color: '#FAAD14' }}>{dataMe?.dataUser.mb_name}</p>
          </S.Avatar>
        )}
        <S.Info>
          <p>
            保有マネー: <span>{dataMe?.dataUser?.mb_money}</span> 원
          </p>
          <S.BoxItemP onClick={() => history.push(UrlInternal.POINT)}>
            포인트: <span>{dataMe?.dataUser?.mb_point}</span> 점
            <img src={changePoint} alt='changePoint' /> 전환
          </S.BoxItemP>
        </S.Info>
      </S.Row>
      <S.Menu>
        <S.Content onClick={() => history.push(UrlInternal.CUSTOMER_CENTER)}>
          <p style={{ color: 'yellow' }}>고객센터</p>
        </S.Content>
        <S.Content onClick={() => history.push(UrlInternal.MESSAGE)}>
          <p style={{ color: 'yellow' }}>쪽지</p>
        </S.Content>
        <S.ContentLogOut onClick={Logout}>
          <p style={{ color: 'red' }}>ログアウト</p>
        </S.ContentLogOut>
      </S.Menu>
    </div>
  )

  return (
    <S.Header className='navbar-overlay'>
      {/* <S.Wrapper> */}
      <S.MenuGroupList onClick={handleShow} show={onShowMenu}></S.MenuGroupList>
      <S.WrapperTop>
        <S.TopLogo onClick={() => history.push(UrlInternal.HOME)}>
          <img className='logo' alt='logo' src={logo} />
        </S.TopLogo>
        <S.MenuWraper>
          {/* <MenuOutlined
            style={{ fontSize: '32px', color: '#08c' }}
            onClick={handleShow}
          > */}
          <S.BtnMenu onClick={handleShow}>
            <img alt='' src={btnMenu} />
          </S.BtnMenu>
          {/* </MenuOutlined> */}
        </S.MenuWraper>

        {/* <S.TopButton show={onShowMenu}>
          <div onClick={() => setOnShowMenu(false)} className='mobile-back'>
            <RiArrowGoBackFill />
          </div>
          {dataMe?.dataUser?.mb_option_rec === '1' && (
            <S.BoxItem onClick={() => window.open(UrlInternal.PARTNER)}>
              <img alt='' src={btnPartner} />
              <span>파트너</span>
            </S.BoxItem>
          )}
          {dataMenu.map((item) => (
            <S.BoxItem
              className={`${pathname === item.url ? 'active' : ''}`}
              key={item.url}
              onClick={() => history.push(item.url)}
            >
              <img alt='' src={item.img} />
              <span>{item.label}</span>
            </S.BoxItem>
          ))}
        </S.TopButton> */}
      </S.WrapperTop>
      {/* </S.Wrapper> */}
      {/* <S.Wrapper>
        <S.WrapperBot>
          {headerMenu.map((v: any, i: number) => {
            return (
              <div className="item" key={i}>
                <div className="content" onClick={() => history.push(v.link)}>
                  {v.label}
                </div>
              </div>
            );
          })}
        </S.WrapperBot>
      </S.Wrapper> */}
    </S.Header>
  )
}

export default HeaderPage
